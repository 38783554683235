module.exports = {
  __init__: [ 'selectionVisuals', 'selectionBehavior' ],
  __depends__: [
    require(43),
    require(45)
  ],
  selection: [ 'type', require(48) ],
  selectionVisuals: [ 'type', require(50) ],
  selectionBehavior: [ 'type', require(49) ]
};
