'use strict';

var assign = require(198);

var BpmnModdle = require(17);

var packages = {
  bpmn: require(19),
  bpmndi: require(20),
  dc: require(21),
  di: require(22)
};

module.exports = function(additionalPackages, options) {
  return new BpmnModdle(assign({}, packages, additionalPackages), options);
};